import { HOST } from './constants';
import { localePathResolver, prismicLinkResolver } from './common';

const getOrganization = (lang: string) => {
  const langResolved = localePathResolver(lang);

  return {
    type: 'Organization',
    url: `https://${HOST}${langResolved}`,
    name: process.env.NEXT_PUBLIC_DEFAULT_PAGE_TITLE,
    logo: `https://${HOST}/ar/assets/logoDH.jpg`,
  };
};

export const getStructuredDataLogo = (lang: string) => {
  const organization = getOrganization(lang);

  return (JSON.stringify({
    '@context': 'https://schema.org',
    '@type': organization.type,
    url: organization.url,
    logo: organization.logo,
  }));
};

export const getStructuredDataCluster = (lang: string, metaTitle, metaDescription) => {
  const organization = getOrganization(lang);

  return (JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Course',
    name: metaTitle,
    description: metaDescription,
    provider: {
      '@type': organization.type,
      name: organization.name,
      sameAs: organization.url,
    },
  }));
};

export const getStructuredVerticalPage = (clusters, lang: string) => {
  const organization = getOrganization(lang);

  return (JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: [
      clusters.map((cluster) => ({
        '@type': 'ListItem',
        position: clusters.indexOf(cluster),
        item: {
          '@type': 'Course',
          url: prismicLinkResolver(cluster, undefined, true),
          name: cluster.data.meta_title,
          description: cluster.data.meta_description,
          provider: {
            '@type': organization.type,
            name: organization.name,
            sameAs: organization.url,
          },
        },
      })),
    ],
  })
  );
};
