/* eslint-disable react/no-danger */
import type { GetStaticProps, GetStaticPropsContext, NextPage } from 'next';
import Head from 'next/head';
import Prismic from '@prismicio/client';
import { Document } from '@prismicio/client/types/documents';
import SliceZone from 'next-slicezone';
import { Ref } from '@prismicio/client/types/ResolvedApi';
import set from 'lodash.set';
import { nanoid } from 'nanoid';
import cloneDeep from 'lodash.clonedeep';
import dynamic from 'next/dynamic';
import resolver from '../sm-resolver';
import useUpdatePreviewRef from '../hooks/useUpdatePreviewRef';
import { getStructuredDataLogo } from '../utils/structuredData';
import {
  getMetaAlternates, getMetaText, getRealLocale, getVerticalId,
} from '../utils/common';
import {
  PRISMIC_API_ENDPOINT, PUBLIC_DEFAULT_LOCALE, SPANISH_LOCALE,
} from '../utils/constants';

const Layout = dynamic(() => import('../components/Layout'), {
  ssr: false,
});

interface Props {
  header: Document;
  page: Document;
  footer: Document;
  preview: { activeRef: Ref };
}

type Page = NextPage<Props> & { Layout?: React.FC };

const Home: Page = ({
  page, header, footer, preview,
}) => {
  useUpdatePreviewRef(preview, page.id);
  const metaTitle = getMetaText(page.data?.meta_title, page.lang);
  const metaDescription = getMetaText(page.data?.meta_description, page.lang);
  const metaKeywords = page.data?.meta_keywords?.map(({ word }) => word)?.filter(Boolean) ?? [];
  const metaAlternate = getMetaAlternates({ pages: [cloneDeep(page), ...page.alternate_languages] });

  return (
    <Layout header={header} footer={footer} resolver={resolver}>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: (getStructuredDataLogo(page.lang as string)) }}
        />
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} key="title" />
        <meta name="description" content={metaDescription} />
        {!!metaKeywords.length && <meta name="keywords" content={metaKeywords.join(', ')} />}
        {metaKeywords.map((word) => <meta key={nanoid()} property="article:tag" content={word} />)}
        {metaAlternate.map(({ lang, href }) => (<link key={nanoid()} rel="alternate" hrefLang={lang} href={href} />))}
      </Head>
      <SliceZone resolver={resolver} slices={page.data?.slices} />
    </Layout>
  );
};

type TPreviewData = GetStaticPropsContext & { previewData? };

export const getStaticProps: GetStaticProps = async ({
  locale,
  previewData,
}: TPreviewData) => {
  const ref = previewData ? previewData.ref : null;

  const currentLocale = getRealLocale(
    locale || PUBLIC_DEFAULT_LOCALE || SPANISH_LOCALE,
  );

  const client = Prismic.client(PRISMIC_API_ENDPOINT);

  const headerRequest = client.getSingle('header', {
    lang: currentLocale,
    ref,
  });

  const footerRequest = client.getSingle('footer', {
    lang: currentLocale,
    ref,
  });

  const pageRequest = client.getSingle('homepage', {
    lang: currentLocale,
    ref,
  });

  const verticalsRequest = client.query(
    [Prismic.predicates.at('document.type', 'verticalpage')],
    {
      lang: currentLocale,
      ref,
    },
  );

  const clustersRequest = client.query(
    [Prismic.predicates.at('document.type', 'clusterpage')],
    {
      lang: currentLocale,
      ref,
      pageSize: 100,
    },
  );

  const productsRequest = client.query(
    [Prismic.predicates.at('document.type', 'products')],
    {
      lang: currentLocale,
      ref,
    },
  );

  const [header, footer, page, verticals, clusters, products] = await Promise.all(
    [headerRequest, footerRequest, pageRequest, verticalsRequest, clustersRequest, productsRequest],
  );

  if (page?.data?.slices) {
    page.data.slices = page.data.slices.map((slice) => ({
      ...slice,
      _page: {
        type: page.type,
      },
      _verticals: verticals?.results,
      _products: products?.results,
      _clusters: clusters?.results,
    }));
  }

  if (!header || !footer || !page) {
    return {
      notFound: true,
    };
  }

  const productsWithVertical = header.data.products.map(
    (item) => set(item, 'link.data.verticalId', getVerticalId(item.link.uid, clusters.results)),
  );

  return {
    props: {
      header: {
        ...header.data,
        products: productsWithVertical,
        clusters: clusters.results,
        verticals: verticals.results,
      },
      footer: footer.data,
      page,
      preview: {
        activeRef: ref,
      },
    },
  };
};

export default Home;
